import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import "./App.css";
import "./responsive.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import ProfitTiers from "./pages/ProfitTiers";
import FAQ from "./pages/FAQ";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PostDetailOne from "./pages/PostDetailOne";
import PostDetailTwo from "./pages/PostDetailTwo";
import PostDetailThree from "./pages/PostDetailThree";
import PostDetail4 from "./pages/PostDetail4";
import PostDetail5 from "./pages/PostDeatil5";
import BlogDetail from "./pages/BlogDetail";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Home />} />
          <Route element={<About />} path="about-us" />
          <Route element={<ProfitTiers />} path="profit-tiers" />
          <Route element={<Contact />} path="contact-us" />
          <Route element={<PrivacyPolicy />} path="privacy-policy" />
          <Route element={<FAQ />} path="faq" />
         <Route path="/blog" element={<Blog />} />
        
        <Route path="/post/:id/:title" element={<BlogDetail />} />

       


        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
